import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  Card,
  Select,
  Layout,
  Form,
  Typography,
  Button,
  Drawer,
  Space,
  Empty,
  Modal,
} from "antd";
import { VirtuosoGrid } from "react-virtuoso";

import {
  CloseOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons";

import AliensGridItem from "../components/AliensGridItem";

import aliens from "../assets/json/aliens.json";
import { Content } from "antd/lib/layout/layout";

const { Sider } = Layout;
const { Title } = Typography;

interface Props {
  className?: string;
}

interface Alien {
  id: string;
  accessory: string;
  environment: string;
  aesthetic: string;
}

const traits: { [key: string]: any } = {
  accessories: [],
  environments: [],
  aesthetics: [],
};

aliens.forEach((alien: Alien) => {
  if (!traits.accessories.includes(alien.accessory)) {
    traits.accessories.push(alien.accessory);
  }

  if (!traits.environments.includes(alien.environment)) {
    traits.environments.push(alien.environment);
  }

  if (!traits.aesthetics.includes(alien.aesthetic)) {
    traits.aesthetics.push(alien.aesthetic);
  }

  traits.accessories.sort();
  traits.environments.sort();
  traits.aesthetics.sort();
});

const options: any = {
  accessories: [],
  environments: [],
  aesthetics: [],
};

const createOptions = (key: any) => {
  options[key] = traits[key].map((item: string) => {
    return {
      label: item,
      value: item,
    };
  });
};

Object.keys(traits).forEach((key: string) => {
  createOptions(key);
});

const ItemContainer = styled.div``;

const ListContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;

  @media (max-width: 2000px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Component = ({ className }: Props): JSX.Element => {
  const [accessoriesSelectValue, setAccessoriesSelectValue] = useState<
    string[]
  >([]);
  const [environmentsSelectValue, setEnvironmentsSelectValue] = useState<
    string[]
  >([]);
  const [aestheticsSelectValue, setAestheticsSelectValue] = useState<string[]>(
    []
  );

  const [filteredAliens, setFilteredAliens] = useState<any[]>([]);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null);

  const virtuosoGrid = useRef(null);

  useEffect(() => {
    const filterValues = {
      accessories: accessoriesSelectValue.length
        ? accessoriesSelectValue
        : options.accessories.map((item: any) => item.value),
      environments: environmentsSelectValue.length
        ? environmentsSelectValue
        : options.environments.map((item: any) => item.value),
      aesthetics: aestheticsSelectValue.length
        ? aestheticsSelectValue
        : options.aesthetics.map((item: any) => item.value),
    };

    const filteredItems = aliens.filter((alien: any) => {
      return (
        filterValues.accessories.includes(alien.accessory) &&
        filterValues.environments.includes(alien.environment) &&
        filterValues.aesthetics.includes(alien.aesthetic)
      );
    });

    setFilteredAliens(filteredItems);

    if (virtuosoGrid && virtuosoGrid.current) {
      const virtuosoGridCurrent: any = virtuosoGrid.current;

      virtuosoGridCurrent.scrollToIndex({
        index: 0,
      });
    }
  }, [accessoriesSelectValue, environmentsSelectValue, aestheticsSelectValue]);

  const baseSelectProps: any = {
    mode: "multiple",
    style: { width: "100%" },
    placeholder: "Select...",
    maxTagCount: "responsive",
    allowClear: true,
  };

  const accessoriesSelectProps: any = {
    value: accessoriesSelectValue,
    options: options.accessories,
    onChange: (newValue: string[]) => {
      setAccessoriesSelectValue(newValue);
    },
  };

  const environmentsSelectProps: any = {
    value: environmentsSelectValue,
    options: options.environments,
    onChange: (newValue: string[]) => {
      setEnvironmentsSelectValue(newValue);
    },
  };

  const aestheticsSelectProps: any = {
    value: aestheticsSelectValue,
    options: options.aesthetics,
    onChange: (newValue: string[]) => {
      setAestheticsSelectValue(newValue);
    },
  };

  const handleClearAllOnClick = () => {
    setAccessoriesSelectValue([]);
    setEnvironmentsSelectValue([]);
    setAestheticsSelectValue([]);
  };

  const handleImageOnClick = (id: number) => {
    setSelectedImageId(id);
    setIsImageModalVisible(true);
  };

  const FiltersForm = () => {
    return (
      <Form layout="vertical">
        <Form.Item className="small-screen-clear-all-button-item">
          <Button
            onClick={handleClearAllOnClick}
            icon={<CloseCircleOutlined />}
            size="small"
          >
            Clear All
          </Button>
        </Form.Item>
        <Form.Item label="Accessories">
          <Select {...{ ...baseSelectProps, ...accessoriesSelectProps }} />
        </Form.Item>

        <Form.Item label="Environments">
          <Select {...{ ...baseSelectProps, ...environmentsSelectProps }} />
        </Form.Item>

        <Form.Item label="Aesthetics">
          <Select {...{ ...baseSelectProps, ...aestheticsSelectProps }} />
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className={`${className}`}>
      <Drawer
        title="Filters"
        placement="left"
        visible={drawerIsVisible}
        onClose={() => setDrawerIsVisible(!drawerIsVisible)}
        className="small-screen-gallery-filters-drawer"
        closable={false}
        width="100%"
        extra={
          <Space>
            <Button
              onClick={() => setDrawerIsVisible(!drawerIsVisible)}
              icon={<CloseOutlined />}
            />
          </Space>
        }
      >
        <FiltersForm />
      </Drawer>
      <Layout>
        <Sider width={300}>
          <div className="filters">
            <header>
              <Title level={4} className="title">
                Filters
              </Title>
              <Button
                size="small"
                onClick={handleClearAllOnClick}
                className="clear-all-button"
                icon={<CloseCircleOutlined />}
              >
                Clear All
              </Button>
            </header>
            <FiltersForm />
          </div>
        </Sider>
        <Layout>
          <Content
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <div className="small-screen-actions">
              <Button
                onClick={() => setDrawerIsVisible(!drawerIsVisible)}
                icon={<FilterOutlined />}
              >
                Filters
              </Button>
            </div>

            {filteredAliens && !filteredAliens.length && (
              <div className="empty-wrapper">
                <Empty
                  description="No results"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                ></Empty>
              </div>
            )}

            {filteredAliens && !!filteredAliens.length && (
              <VirtuosoGrid
                ref={virtuosoGrid}
                style={{ height: "100%" }}
                totalCount={filteredAliens.length}
                components={{
                  Item: ItemContainer,
                  List: ListContainer,
                }}
                itemContent={(index) => (
                  <AliensGridItem
                    index={index}
                    filteredAliens={filteredAliens}
                    handleImageOnClick={handleImageOnClick}
                  />
                )}
              />
            )}
          </Content>
        </Layout>
      </Layout>
      <Modal
        title={`#${selectedImageId}`}
        visible={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        footer={null}
        className="image-modal"
        centered
      >
        <div className="img-wrap ">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/${selectedImageId}.png`}
            alt={`#${selectedImageId}`}
          />
        </div>
      </Modal>
    </div>
  );
};

const Aliens = styled(Component)`
  height: 100%;
  .ant-layout {
    height: 100%;
  }
  .ant-layout-sider {
    margin-right: 20px;
    background: transparent;
    border: 1px solid #303030;
  }
  .description {
    .label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .ant-card {
    background: transparent;
  }
  .ant-card-cover {
    padding: 20px;
  }
  .filters {
    padding: 20px;
    header {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        margin: 0;
        line-height: 1em;
      }
      .clear-all-button {
        font-size: 14px;
        position: relative;
        top: 2px;
      }
    }
    .label {
      height: 32px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 16px;
    }
  }
  .small-screen-actions {
    margin-bottom: 20px;
    display: none;
  }
  .empty-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1100px) {
    .ant-layout-sider {
      display: none;
    }
    .small-screen-actions {
      display: flex;
    }
    .small-screen-gallery-filters-drawer {
      display: block;
    }
  }
`;

export default Aliens;
