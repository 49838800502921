import React, { useState } from "react";
import styled from "styled-components";
import { Card, Button, Modal } from "antd";
import { InfoCircleOutlined, ReadOutlined } from "@ant-design/icons";
import ReactCardFlip from "react-card-flip";
import axios from "axios";

interface Props {
  className?: string;
  index: number;
  filteredStories: any[];
  handleImageOnClick: (id: number) => void;
}

const Component = ({
  className,
  index,
  filteredStories,
  handleImageOnClick,
}: Props): JSX.Element => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [viewText, setViewText] = useState(false);
  const [text, setText] = useState(false);
  const item = filteredStories[index];

  const handleTextButtonOnClick = async () => {
    const response: any = await axios.get(
      `${process.env.PUBLIC_URL}/assets/stories-text/${item.id}.txt`
    );

    setText(response.data);
    setViewText(true);
  };

  const Description = ({ item }: any) => {
    return (
      <div className="description">
        <div className="content">
          <div className="content-inner">
            <div>
              <span className="label">Prompt:</span>
              <span className="value">{item.prompt}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CardTitle = ({ id }: { id: string }) => {
    return (
      <div className="card-title">
        <span>#{id}</span>
        <div className="actions">
          <Button
            size="small"
            onClick={handleTextButtonOnClick}
            icon={<ReadOutlined />}
          >
            Text
          </Button>
          <Button
            size="small"
            onClick={() => setIsFlipped(!isFlipped)}
            icon={<InfoCircleOutlined />}
          >
            Info
          </Button>
        </div>
      </div>
    );
  };

  const handleCancel = () => {
    setViewText(false);
  };

  return (
    <>
      <Card
        title={<CardTitle id={item.id} />}
        className={`${className}`}
        size="small"
      >
        <ReactCardFlip isFlipped={isFlipped}>
          <div className="img-wrap">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/stories-images/${item.id}.png`}
              alt={item.id}
              onClick={() => handleImageOnClick(item.id)}
            />
          </div>

          <Description item={item} />
        </ReactCardFlip>
      </Card>
      <Modal
        title={`#${item.id}`}
        visible={viewText}
        onCancel={handleCancel}
        footer={null}
        centered
        width={800}
        bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
      >
        <div>
          <pre className="story-text">{text}</pre>
        </div>
      </Modal>
    </>
  );
};

const StoriesGridItem = styled(Component)`
  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-btn {
      font-size: 14px;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
  .img-wrap {
    padding-bottom: 100%;
    height: 0;
    position: relative;
  }
  .ant-card-body img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    cursor: pointer;
  }
  .description {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      font-size: 14px;
    }
    img {
      opacity: 0;
    }
  }
`;

export default StoriesGridItem;
