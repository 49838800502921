import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import GlobalStyle from "./globalStyles";
import "./App.less";

import { Layout } from "antd";

import useMintNft from "./hooks/useMintNft";
import { appStore, onAppMount } from "./state/app";

import SiteHeader from "./components/SiteHeader";
import SiteContent from "./components/SiteContent";
import SiteFooter from "./components/SiteFooter";

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  const { dispatch } = useContext(appStore);

  const onMount = () => {
    dispatch(onAppMount());
  };

  useEffect(onMount, []);
  const { state } = useContext(appStore);

  return (
    <>
      <GlobalStyle />
      <Layout className={`${className}`}>
        <div className="content-wrap">
          <SiteHeader />
          <SiteContent />
        </div>

        <SiteFooter />
      </Layout>
    </>
  );
};

const App = styled(Component)`
  position: relative;
  min-height: 100vh;
  display: block;
  .content-wrap {
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;
export default App;
