import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  Card,
  Select,
  Layout,
  Form,
  Typography,
  Button,
  Drawer,
  Space,
  Empty,
  Modal,
} from "antd";
import { VirtuosoGrid } from "react-virtuoso";

import {
  CloseOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons";

import StoriesGridItem from "../components/StoriesGridItem";

import stories from "../assets/stories-json/stories-traits.json";
import { Content } from "antd/lib/layout/layout";

const { Sider } = Layout;
const { Title } = Typography;

interface Props {
  className?: string;
}

interface Story {
  id: number;
  prompt: string;
}

const traits: { [key: string]: any } = {
  prompts: [],
};

stories.forEach((story: Story) => {
  if (!traits.prompts.includes(story.prompt)) {
    traits.prompts.push(story.prompt);
  }

  traits.prompts.sort();
});

const options: any = {
  prompts: [],
};

const createOptions = (key: any) => {
  options[key] = traits[key].map((item: string) => {
    return {
      label: item,
      value: item,
    };
  });
};

Object.keys(traits).forEach((key: string) => {
  createOptions(key);
});

const ItemContainer = styled.div``;

const ListContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;

  @media (max-width: 2000px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Component = ({ className }: Props): JSX.Element => {
  const [promptsSelectValue, setPromptsSelectValue] = useState<string[]>([]);

  const [filteredStories, setFilteredStories] = useState<any[]>([]);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null);

  const virtuosoGrid = useRef(null);

  useEffect(() => {
    const filterValues = {
      prompts: promptsSelectValue.length
        ? promptsSelectValue
        : options.prompts.map((item: any) => item.value),
    };

    const filteredItems = stories.filter((alien: any) => {
      return filterValues.prompts.includes(alien.prompt);
    });

    setFilteredStories(filteredItems);

    if (virtuosoGrid && virtuosoGrid.current) {
      const virtuosoGridCurrent: any = virtuosoGrid.current;

      virtuosoGridCurrent.scrollToIndex({
        index: 0,
      });
    }
  }, [promptsSelectValue]);

  const baseSelectProps: any = {
    mode: "multiple",
    style: { width: "100%" },
    placeholder: "Select...",
    maxTagCount: "responsive",
    allowClear: true,
  };

  const promptsSelectProps: any = {
    value: promptsSelectValue,
    options: options.prompts,
    onChange: (newValue: string[]) => {
      setPromptsSelectValue(newValue);
    },
  };

  const handleClearAllOnClick = () => {
    setPromptsSelectValue([]);
  };

  const handleImageOnClick = (id: number) => {
    setSelectedImageId(id);
    setIsImageModalVisible(true);
  };

  const FiltersForm = () => {
    return (
      <Form layout="vertical">
        <Form.Item className="small-screen-clear-all-button-item">
          <Button
            onClick={handleClearAllOnClick}
            icon={<CloseCircleOutlined />}
            size="small"
          >
            Clear All
          </Button>
        </Form.Item>
        <Form.Item label="Prompts">
          <Select
            {...{ ...baseSelectProps, ...promptsSelectProps }}
            mode="single"
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className={`${className}`}>
      <Drawer
        title="Filters"
        placement="left"
        visible={drawerIsVisible}
        onClose={() => setDrawerIsVisible(!drawerIsVisible)}
        className="small-screen-gallery-filters-drawer"
        closable={false}
        width="100%"
        extra={
          <Space>
            <Button
              onClick={() => setDrawerIsVisible(!drawerIsVisible)}
              icon={<CloseOutlined />}
            />
          </Space>
        }
      >
        <FiltersForm />
      </Drawer>
      <Layout>
        <Sider width={300}>
          <div className="filters">
            <header>
              <Title level={4} className="title">
                Filters
              </Title>
              <Button
                size="small"
                onClick={handleClearAllOnClick}
                className="clear-all-button"
                icon={<CloseCircleOutlined />}
              >
                Clear All
              </Button>
            </header>
            <FiltersForm />
          </div>
        </Sider>
        <Layout>
          <Content
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <div className="small-screen-actions">
              <Button
                onClick={() => setDrawerIsVisible(!drawerIsVisible)}
                icon={<FilterOutlined />}
              >
                Filters
              </Button>
            </div>

            {filteredStories && !filteredStories.length && (
              <div className="empty-wrapper">
                <Empty
                  description="No results"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                ></Empty>
              </div>
            )}

            {filteredStories && !!filteredStories.length && (
              <VirtuosoGrid
                ref={virtuosoGrid}
                style={{ height: "100%" }}
                totalCount={filteredStories.length}
                components={{
                  Item: ItemContainer,
                  List: ListContainer,
                }}
                itemContent={(index) => (
                  <StoriesGridItem
                    index={index}
                    filteredStories={filteredStories}
                    handleImageOnClick={handleImageOnClick}
                  />
                )}
              />
            )}
          </Content>
        </Layout>
      </Layout>
      <Modal
        title={`#${selectedImageId}`}
        visible={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        footer={null}
        className="image-modal"
        centered
      >
        <div className="img-wrap ">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/stories-images/${selectedImageId}.png`}
            alt={`#${selectedImageId}`}
          />
        </div>
      </Modal>
    </div>
  );
};

const Aliens = styled(Component)`
  height: 100%;
  .ant-layout {
    height: 100%;
  }
  .ant-layout-sider {
    margin-right: 20px;
    background: transparent;
    border: 1px solid #303030;
  }
  .description {
    .label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .ant-card {
    background: transparent;
  }
  .ant-card-cover {
    padding: 20px;
  }
  .filters {
    padding: 20px;
    header {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        margin: 0;
        line-height: 1em;
      }
      .clear-all-button {
        font-size: 14px;
        position: relative;
        top: 2px;
      }
    }
    .label {
      height: 32px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 16px;
    }
  }
  .small-screen-actions {
    margin-bottom: 20px;
    display: none;
  }
  .empty-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1100px) {
    .ant-layout-sider {
      display: none;
    }
    .small-screen-actions {
      display: flex;
    }
    .small-screen-gallery-filters-drawer {
      display: block;
    }
  }
`;

export default Aliens;
