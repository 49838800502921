import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Layout, Menu, Button, Drawer, Space } from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  HomeOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import logoImage from "../assets/images/ai-alien-logo.svg";
import { BREAKPOINT_SM, LOGO_TEXT } from "../constants";
import { DiscordIcon } from "../components/DiscordIcon";

const { Header } = Layout;

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  const [drawerMenuIsVisible, setDrawerMenuIsVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawMenuVisibilty = () => {
    setDrawerMenuIsVisible(!drawerMenuIsVisible);
  };

  const handleMenuItemOnClick = (e: any, location: string) => {
    e.preventDefault();
    navigate(`/${location}`);
    if (drawerMenuIsVisible) {
      setTimeout(() => {
        setDrawerMenuIsVisible(false);
      }, 250);
    }
  };

  const NavigationMenu = (props: any) => {
    return (
      <Menu
        theme="dark"
        mode={props.mode}
        selectedKeys={[location.pathname.slice(1)]}
      >
        <Menu.Item key="home" className="home-menu-item">
          <a href="/" onClick={(e: any) => handleMenuItemOnClick(e, "")}>
            <HomeOutlined />
          </a>
        </Menu.Item>
        <Menu.Item key="about">
          <a href="/" onClick={(e: any) => handleMenuItemOnClick(e, "about")}>
            About
          </a>
        </Menu.Item>
        <Menu.Item key="aliens">
          <a href="/" onClick={(e: any) => handleMenuItemOnClick(e, "aliens")}>
            Aliens
          </a>
        </Menu.Item>
        <Menu.Item key="stories">
          <a href="/" onClick={(e: any) => handleMenuItemOnClick(e, "stories")}>
            Stories
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Header className={`${className}`} style={{ zIndex: 1, width: "100%" }}>
      <Link to="/" className="branding">
        <div className="logo">
          <img src={logoImage} alt="logo" />
          <h1>{LOGO_TEXT}</h1>
        </div>
      </Link>

      <NavigationMenu mode="horizontal" />
      <div className="socials">
        <div className="item">
          <a
            href="https://discord.gg/pdjN8vE9Sc"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
        </div>
        <div className="item">
          <a
            href="https://twitter.com/aigenaliens"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterOutlined />
          </a>
        </div>
      </div>
      <Button
        className="menu-button"
        icon={<MenuOutlined />}
        onClick={toggleDrawMenuVisibilty}
      />
      <Drawer
        className="drawer-menu"
        title="Navigation"
        placement="left"
        visible={drawerMenuIsVisible}
        width="220"
        closable={false}
        extra={
          <Space>
            <Button
              onClick={toggleDrawMenuVisibilty}
              icon={<CloseOutlined />}
            />
          </Space>
        }
      >
        <NavigationMenu mode="vertical" />
      </Drawer>
    </Header>
  );
};

const SiteHeader = styled(Component)`
  border-bottom: 1px solid #535353;
  position: sticky;
  top: 0;
  z-index: 2 !important;
  background: #000;
  &.ant-layout-header {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      padding: 0 30px;
    }
  }
  .ant-menu {
    flex: 1;
    background: transparent;
  }
  .logo {
    margin-right: 30px;
    display: flex;
    align-items: center;
    img {
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }
    h1 {
      font-family: "Roboto Mono", monospace;
      font-size: 26px;
      position: relative;
      top: -2px;
      margin: 0;
    }
  }
  .ant-menu-item {
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .menu-link {
    &.menu-link-active {
      border-bottom: 1px solid;
    }
  }
  .menu-button {
    display: none;
  }
  .socials {
    display: flex;
    .item {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 720px) {
    &.ant-layout-header {
      justify-content: space-between;
    }
    .branding {
      flex: 1;
    }
    .ant-menu {
      display: none;
    }
    .socials {
      margin-right: 20px;
    }
    .menu-button {
      display: block;
    }
  }

  @media only screen and (max-width: 470px) {
    .logo {
      h1 {
        display: none;
      }
    }
  }
`;

export default SiteHeader;
