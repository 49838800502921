import React, { useState } from "react";
import styled from "styled-components";
import { Card, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ReactCardFlip from "react-card-flip";

interface Props {
  className?: string;
  index: number;
  filteredAliens: any[];
  handleImageOnClick: (id: number) => void;
}

const Component = ({
  className,
  index,
  filteredAliens,
  handleImageOnClick,
}: Props): JSX.Element => {
  const [isFlipped, setIsFlipped] = useState(false);
  const item = filteredAliens[index];

  const Description = ({ item }: any) => {
    return (
      <div className="description">
        <div className="content">
          <div className="content-inner">
            <div>
              <span className="label">Accessory:</span>
              <span className="value">{item.accessory}</span>
            </div>
            <div>
              <span className="label">Environment:</span>
              <span className="value">{item.environment}</span>
            </div>
            <div>
              <span className="label">Aesthetic:</span>
              <span className="value">{item.aesthetic}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CardTitle = ({ id }: { id: string }) => {
    return (
      <div className="card-title">
        <span>#{id}</span>
        <Button
          size="small"
          onClick={() => setIsFlipped(!isFlipped)}
          icon={<InfoCircleOutlined />}
        >
          Info
        </Button>
      </div>
    );
  };

  return (
    <Card
      title={<CardTitle id={item.id} />}
      className={`${className}`}
      size="small"
    >
      <ReactCardFlip isFlipped={isFlipped}>
        <div className="img-wrap">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/${item.id}.png`}
            alt={item.id}
            onClick={() => handleImageOnClick(item.id)}
          />
        </div>

        <Description item={item} />
      </ReactCardFlip>
    </Card>
  );
};

const AliensGridItem = styled(Component)`
  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-btn {
      font-size: 14px;
    }
  }
  .img-wrap {
    padding-bottom: 100%;
    height: 0;
    position: relative;
  }
  .ant-card-body img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    cursor: pointer;
  }
  .description {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      font-size: 14px;
    }
    img {
      opacity: 0;
    }
  }
`;

export default AliensGridItem;
