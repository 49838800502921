import React from "react";
import styled from "styled-components";
import { Typography, Card, Statistic } from "antd";

import { BREAKPOINT_SM } from "../constants";

const { Title } = Typography;

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  const CardTitle = ({
    role,
    name,
    url,
  }: {
    role: string;
    name: string;
    url: string;
  }) => {
    return (
      <div>
        <div>
          <a href={url} target="_blank" rel="noreferrer">
            {name}
          </a>
        </div>
        <div>{role}</div>
      </div>
    );
  };
  const LicenceValueFormatter = (value: string | number) => {
    return (
      <a
        href="https://creativecommons.org/publicdomain/zero/1.0/"
        target="_blank"
        rel="noreferrer"
      >
        {value}
      </a>
    );
  };
  return (
    <div className={`${className}`}>
      <section>
        <header>
          <Title level={3}>Info</Title>
        </header>
        <div className="section-content statistics">
          <Card>
            <Statistic title="Mint price" value={"Free"} />
          </Card>
          <Card>
            <Statistic title="Total supply" value={111} />
          </Card>
          <Card>
            <Statistic title="Max. per wallet" value={3} />
          </Card>
          <Card>
            <Statistic title="Sale royalties" value={"10%"} />
          </Card>
          <Card>
            <Statistic title="Team allocation" value={5} />
          </Card>
          <Card>
            <Statistic
              title="Asset licence"
              value={"CC0 1.0"}
              formatter={LicenceValueFormatter}
            />
          </Card>
        </div>
      </section>
      <section>
        <header>
          <Title level={3}>Team</Title>
        </header>
        <div className="section-content team">
          <Card
            title={
              <CardTitle
                name="Buidles"
                role="Developer"
                url="https://twitter.com/Buidles1"
              />
            }
          >
            <div className="img-wrap">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/team-pics-thumbs/dev.png`}
                alt="Developer"
              />
            </div>
          </Card>

          <Card
            title={
              <CardTitle
                name="Midjourney Bot"
                role="Artist"
                url="https://twitter.com/midjourney"
              />
            }
          >
            <div className="img-wrap">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/team-pics-thumbs/artist.png`}
                alt="Artist"
              />
            </div>
          </Card>
        </div>
      </section>
      <section>
        <header>
          <Title level={3}>Roadmap</Title>
        </header>
        <div className="section-content">
          <p>
            Beyond market place listings there is no roadmap - we're not going
            to be sitting on our hands though.
          </p>
        </div>
      </section>
    </div>
  );
};

const About = styled(Component)`
  section {
    margin-bottom: 40px;
    h3 {
      margin: 0;
    }
  }
  .section-content {
    padding: 20px 0 20px;
  }
  .statistics {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    @media (max-width: 1180px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 460px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .team {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    @media (max-width: 1500px) {
      grid-template-columns: repeat(6, 2fr);
    }

    @media (max-width: 530px) {
      grid-template-columns: repeat(1, 1fr);
      .ant-card {
        min-width: auto;
      }
    }
    .ant-card {
      min-width: 220px;
    }
  }
  .ant-card {
    background: transparent;
  }
  .img-wrap {
    padding-bottom: 100%;
    height: 0;
    position: relative;
  }
  .ant-card-body img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }
  @media only screen and (max-width: ${BREAKPOINT_SM}) {
    .column {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default About;
