import React, { useContext } from "react";
import styled from "styled-components";
import { Button, Dropdown, Menu } from "antd";

// import { appStore } from "../state/app";
// import useMintNft from "../hooks/useMintNft";

import Carousel from "../components/Carousel";
import { LOGO_TEXT } from "../constants";

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  // const { state } = useContext(appStore);
  // const { mintNft } = useMintNft();

  // const { wallet, account } = state;

  // const handleMintButtonOnClick = () => {
  //   if (!wallet || !wallet.signedIn) {
  //     const successUrl = `${window.location.origin}`;
  //     wallet.signIn(successUrl);
  //   } else {
  //     mintNft();
  //   }
  // };

  const onMenuClick = (e: { key: any }) => {
    let url;

    if (e.key === "paras") {
      url = "https://paras.id/collection/aigenaliens.near";
    }

    if (e.key === "few-and-far") {
      url = "https://fewfar.com/collection/aigenaliens.near";
    }

    if (url) {
      window.open(url, "_blank")?.focus();
    }
  };

  const menu = (
    <Menu
      onClick={onMenuClick}
      items={[
        {
          key: "paras",
          label: "Paras",
        },
        {
          key: "few-and-far",
          label: "Few and Far",
        },
      ]}
    />
  );

  return (
    <div className={`${className}`}>
      <section className="text-content-section">
        <img
          className="logo-image"
          src="/static/media/ai-alien-logo.6ae2005a.svg"
          alt="logo"
        />
        <h2>{LOGO_TEXT}</h2>
        <p>
          A unique collection of 111 AI generated NFTs, residing on the NEAR
          blockchain.
        </p>
        <br />

        <Dropdown.Button overlay={menu} type="primary" size="large">
          Marketplaces
        </Dropdown.Button>

        {/* <Button type="primary" shape="round" size="large" disabled>
          Coming Soon
        </Button> */}

        {/* {account?.accountId ? (
          <div className="connected-actions">
            {!state.app.soldOut && (
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleMintButtonOnClick}
              >
                Mint
              </Button>
            )}
            {state.app.soldOut && (
              <Button type="primary" shape="round" size="large" disabled>
                Sold Out
              </Button>
            )}
            <Button
              onClick={() => wallet?.signOut()}
              shape="round"
              size="large"
            >
              Disconnect Wallet
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => {
              wallet?.signIn();
            }}
            type="primary"
            shape="round"
            size="large"
          >
            Connect Wallet
          </Button>
        )} */}
      </section>
      <section className="carousel-section">
        <Carousel />
      </section>
    </div>
  );
};

const Home = styled(Component)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  height: 100%;
  section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .logo-image {
      width: 100px;
      height: 100px;
    }
    h2 {
      font-family: "Roboto Mono", monospace;
      font-size: 70px;
      margin-bottom: 20px;
      @media (max-width: 650px) {
        font-size: 50px;
      }
    }
    .connected-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(1, 1fr);
    .carousel-section {
      align-self: flex-start;
    }
  }
`;

export default Home;
