// globalStyles.js
import { createGlobalStyle } from "styled-components";

import { BREAKPOINT_SM } from "./constants";

const GlobalStyles = createGlobalStyle`
body {
  min-width: 300px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
canvas {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}
#root {
  height: 100%;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.9);
}
.image-modal {
  .ant-modal-content {
    background: #000;
    border: 1px solid #303030;
  }
  .ant-modal-header {
    background: #000;
  }
 
  .img-wrap {
    padding-bottom: 100%;
    height: 0;
    position: relative;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }
}
.story-text {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.drawer-menu {
    display: none;
    .ant-menu-item {
        padding: 0;
    }
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
  .small-screen-gallery-filters-drawer {
    display: none;
  }
  .small-screen-clear-all-button-item {
    .ant-btn {
      font-size: 14px;
    }
 
    display: none;
  }
  @media only screen and (max-width: ${BREAKPOINT_SM}) {
    .drawer-menu {
      display: block;
    }
  }
  @media only screen and (max-width: 1000px) {
    .small-screen-gallery-filters-drawer {
      display: block;
     
    }
    .small-screen-clear-all-button-item {
      display: block;
    }
  }

  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: rgb(97 97 97 / 40%);
  }
`;

export default GlobalStyles;
