import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ResizeObserver from "resize-observer-polyfill";

import App from "./App";
import { AppProvider } from "./state/app";

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
