import React from "react";
import styled from "styled-components";
import { Layout } from "antd";

import { LOGO_TEXT } from "../constants";

const { Footer } = Layout;

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  return (
    <Footer className={`${className}`}>
      <small>
        &copy; {LOGO_TEXT} {new Date().getFullYear()}
      </small>
    </Footer>
  );
};

const SiteFooter = styled(Component)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
  border-top: 1px solid #535353;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    padding: 0 30px;
  }
`;

export default SiteFooter;
