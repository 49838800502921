import React from "react";
import styled from "styled-components";
import { Autoplay, EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss";
import "swiper/modules/autoplay/autoplay.scss";
import "swiper/modules/effect-cards/effect-cards.scss";
import "swiper/modules/virtual/virtual.scss";

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  return (
    <div className={`${className}`}>
      <Swiper
        effect={"cards"}
        modules={[Autoplay, EffectCards]}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/2.png`}
            alt="1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/5.png`}
            alt="2"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/10.png`}
            alt="3"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/19.png`}
            alt="4"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/40.png`}
            alt="5"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/46.png`}
            alt="6"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/109.png`}
            alt="7"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/75.png`}
            alt="8"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/52.png`}
            alt="9"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/aliens-thumbs/30.png`}
            alt="10"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const Carousel = styled(Component)`
  img {
    max-width: 100%;
    height: auto;
    border-radius: 2px;
  }
  .swiper {
    width: 400px;
    height: 400px;
    @media (max-width: 1200px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 500px) {
      width: 250px;
      height: 250px;
    }
    @media (max-width: 400px) {
      width: 200px;
      height: 200px;
    }
    @media (max-width: 350px) {
      width: 150px;
      height: 150px;
    }
  }
  .swiper-slide {
    border: 1px solid #303030;
    border-radius: 2px;
    padding: 12px;
    background: #000;
  }
`;

export default Carousel;
