import React from "react";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import Home from "../pages/Home";
import About from "../pages/About";
import Aliens from "../pages/Aliens";
import Stories from "../pages/Stories";

const { Content } = Layout;

interface Props {
  className?: string;
}

const Component = ({ className }: Props): JSX.Element => {
  return (
    <div className={`${className}`}>
      <Content>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="aliens" element={<Aliens />} />
          <Route path="stories" element={<Stories />} />
        </Routes>
      </Content>
    </div>
  );
};

const SiteContent = styled(Component)`
  padding: 50px;
  z-index: 1;
  flex: 1;
  display: flex;
  @media (max-width: 600px) {
    padding: 30px;
  }
`;

export default SiteContent;
